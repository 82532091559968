.heading {
      color: white;
      font-size: 3em;
      max-width: 640px;
      margin: auto auto;
      text-align: center;
      text-shadow: 2px 2px 50px black;
    text-transform: uppercase;
      font-weight: 700;
      padding-top: 200px;
   }
.image {
   background: 
		linear-gradient(
	      rgba(46, 49, 49, 0.4), 
	      rgba(108, 122, 137, 0.8)
	    ),
	    url(./img/prc-header.jpg);
   background-size: cover;
   background-position: center center;
   height: 600px;
   margin-top: -20px;
   text-align: center;
   
  

   a {
      text-align: center;
      padding: 20px 60px;
      
       .btn {
         margin-top: 40px;
         background-color: transparent;
         color: white;
         border: 1px solid white;
         padding: 10px;
         font-size: 1.2em;
         border-radius: 0;
             
          &:hover {
             color: $dark;
             border-color: none;
             background-color: white;
          }
      }
   }
}
.menu-item {
  display: inline;
  @include phone {
    display: block;
  }
  a {
    color: white;
    font-weight: 700;
    text-transform: uppercase;
    margin: $base-unit;
    &:hover {
      text-decoration: none;
      opacity: 0.6;
    }
  }
}
// .navbar {
//   // height: 80px;
//   background-color: transparent;
//   border: none;
//   color: white;
//   z-index: 100;
//   transition: background-color 1s ease 0s;

//   li {
//     list-style: none;
//     a {
//       color: white;
//       text-transform: uppercase;
//       font-weight:700;
//       &:hover {
//         opacity: 0.5;
//         text-decoration: none;
//       }
//     }
//   }
// }


.navbar{
  @include tablet-down{
    background-color: $dark;
  }
}

  .navbar-logo svg {
    margin: $base-unit 0;
     width:200px;
   }

// .navbar-default {
  
 
   
//   .navbar-nav {
    
//     > li {
      
//       > a {
//         color: white;
//         margin: 10px 5px 5px 5px;
        
//       }
//       > a:hover, > a:active, > a:focus {
//         color: #C57ED3;
//         border: 1px solid #C57ED3;
//       }
//       > a:visited {
//         color: #C57ED3;
//         text-decoration: none;
//       }

//     } //end li

//     > .active {

//       > a, > a:hover, > a:focus {
//         background-color: transparent;
//         color: #C57ED3;
//       }

//     }

//   } //end .navbar-nav

//   //Collapsed styles
//   .navbar-toggle {
//     border-color: #C57ED3;
//     position: absolute;
//     top: 50%;
//     right: 16px;
//     width: 48px;
//     height: 48px;
//     @include transform(translateY(-50%));

//       &:hover, &:focus {
//         background-color: #490D40;
//       }



//       .icon-bar {
//         color: #C57ED3;
//         background-color: #C57ED3;

//       }
//   }

//   .navbar-collapse.collapse.in ul {
//     background-color: #490D40;
//   }
    
// } //end .navbar-default



/* Solid class attached on scroll past first section */
// .navbar{
// 	position: fixed;
// 	top: 0;
// 	left: 0;
// 	width: 100%;
// 	display: block;
// }

.navbar-nav > li > a {
            color: white;
            transition: color 1s ease 0s;


    } 

.navbar.solid {
  background-color: $dark;
  transition: background-color 1s ease 0s;
  box-shadow: 0 0 4px grey;



}

.navbar-toggler{
  color: white;
  // background-color: orange;
}

.navbar-toggler-icon{
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E")
}







