@mixin vertical-align {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

@mixin transform($transforms) {
     -moz-transform: $transforms;
       -o-transform: $transforms;
      -ms-transform: $transforms;
  -webkit-transform: $transforms;
          transform: $transforms;
}

// Usage
// .element p {
//   @include vertical-align;
// }

@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie); //IE8
}

@mixin mq($property, $phone, $tablet, $laptop, $desktop, $large) {
  @include phone{
    $property: $phone;
  }
  @include tablet{
    $property: $tablet;
  }
  @include laptop{
    $property: $laptop;
  }
  @include desktop{
    $property: $desktop;
  }
  @include large{
    $property: $large;
  }
}

@mixin font-size($phone, $tablet, $laptop, $desktop, $large){
  @include phone{
    font-size: $phone;
  }
  @include tablet{
    font-size: $tablet;
  }
  @include laptop{
    font-size: $laptop;
  }
  @include desktop{
    font-size: $desktop;
  }
  @include large{
    font-size: $large;
  }
}

@mixin width($phone, $tablet, $laptop, $desktop, $large){
  @include phone{
    width: $phone;
  }
  @include tablet{
    width: $tablet;
  }
  @include laptop{
    width: $laptop;
  }
  @include desktop{
    width: $desktop;
  }
  @include large{
    width: $large;
  }
}

@mixin margin($phone, $tablet, $laptop, $desktop, $large){
  @include phone{
    margin: $phone;
  }
  @include tablet{
    margin: $tablet;
  }
  @include laptop{
    margin: $laptop;
  }
  @include desktop{
    margin: $desktop;
  }
  @include large{
    margin: $large;
  }
}


@mixin overlay($opacity){
  position: relative;
  &::after{
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0px;
    background: #333;
    @include opacity($opacity);
  }
}

@mixin color_overlay($opacity, $background){
  position: relative;
  &::after{
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 0;
    top: 0px;
    left: 0px;
    background: $background;
    @include opacity($opacity);
  }
}

@mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}

@mixin perspective($val) {
  -webkit-perspective: $val; 
  -moz-perspective: $val; 
  -ms-perspective: $val; 
  perspective: $val;
}



@mixin placeholder {
  &::-webkit-input-placeholder {@content}
  &:-moz-placeholder           {@content}
  &::-moz-placeholder          {@content}
  &:-ms-input-placeholder      {@content}  
}

@mixin vertical-align {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

// .element p {
//   @include vertical-align;
// }

@mixin smart-bg{
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}


@mixin center {
  margin-left: auto;
  margin-right: auto;
  float: none;
  display: block;
}

@mixin alpha-background-color($color, $background) {
  $percent: alpha($color) * 100%;
  $opaque: opacify($color, 1);
  $solid-color: mix($opaque, $background, $percent);
  background-color: $solid-color;
  background-color: $color;
}

//USAGE

// @include alpha-background-color(rgba(#333, 0.7), #333);


//USAGE

// @include alpha-background-color(rgba(#333, 0.7), #333);


// Defining values
// $screen-sm-min: 768px;
// $screen-xs-max: ($screen-sm-min - 1);
// $screen-md-min: 992px;
// $screen-sm-max: ($screen-md-min - 1);
// $screen-lg-min: 1200px;
// $screen-md-max: ($screen-lg-min - 1);
 
// Usage
// @media (max-width: $screen-xs-max) { ... }
// @media (min-width: $screen-sm-min) { ... }
// @media (max-width: $screen-sm-max) { ... }
// @media (min-width: $screen-md-min) { ... }
// @media (max-width: $screen-md-max) { ... }
// @media (min-width: $screen-lg-min) { ... }


$large-screen-width: 1500px;
//Large Devices, Desktop
$desktop-width: 1200px;
//Medium Devices, Laptop
$laptop-width: 992px;
//Small Devices, Tablets 
$tablet-width: 768px;
//Extra Small Devices, Phones 
$phone-width: 576px;

$mobile-nav-width: 1000px;

@mixin mobile_nav_width {
  @media (max-width: $mobile-nav-width) {
    @content;
  }
}


// For Phones (480px and down)
@mixin phone {
  @media (max-width: $phone-width - 1px) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$phone-width}) and (max-width: #{$tablet-width - 1px})  {
    @content;
  }
}

@mixin tablet-down {
  @media (max-width: #{$tablet-width - 1px})  {
    @content;
  }
}

@mixin tablet-up {
  @media (min-width: #{$phone-width}) {
    @content;
  }
}


@mixin laptop {
  @media (min-width: #{$tablet-width}) and (max-width: #{$laptop-width - 1px})  {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$laptop-width}) and (max-width: #{$desktop-width - 1px})  {
    @content;
  }
}



@mixin large {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

@mixin xl {
  @media (min-width: #{$large-screen-width}) {
    @content;
  }
}


.mq{
  position: fixed;
  top: 0px;
  left: 0px;
  background: orange;
  z-index: 100000;
  color: white;
  padding: 10px;
  @include phone{
    &::before{
    content:'phone';
    }
  }
    @include tablet{
      &::before{
    content:'tablet';
  }
  }
    @include laptop{
      &::before{
    content:'laptop';
  }
  }
    @include desktop{
      &::before{
    content:'desktop';
  }
  }
    @include large{
      &::before{
    content:'large';
  }
  }
}

