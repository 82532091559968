h1{
    font-family: $accent-font;

}

h2 {   
    // text-transform: uppercase;
    font-weight: 700;
    // letter-spacing: 0.1em;
    font-family: $accent-font;
}

h3 {
    text-transform: uppercase;
    font-weight: 600;
    font-family: $accent-font;
    margin: 1rem 0;
}

h4 {
    text-transform: uppercase;
    font-weight: 500;
    font-family: $accent-font;
}

h6 {
    text-transform: uppercase;
    opacity: 0.6;
    font-size: 0.8em;
}

.page-title {
    text-align: center;
    font-size: 3em;
    padding: 90px 0;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 0.1em;
    font-family: $accent-font;

    @include phone {
        padding: 40px 0;
    }
  }

p {
	font-size: 1.1em;
	line-height: 2em;
    font-family: $main-font;
	font-weight: 400;
	margin-bottom: 20px;

    @include phone {
        font-size: 1em;
        line-height: 1.5em;
    }

}

li {
	font-size: 1.1em;
    line-height: 2em;
    font-family: $main-font;
    font-weight: 400;
    margin-bottom: 0;
}

cite {
    font-size: 0.7em;
}

