.image-contact {
   background: 
		linear-gradient(
	      rgba(46, 49, 49, 0.4), 
	      rgba(108, 122, 137, 0.8)
	    ),
	    url(./img/prc.jpg);
   background-size: cover;
   background-position: center center;
   height: 500px;
   margin-top: -20px;
   
   .heading {
      // letter-spacing: 0.1em;
      color: white;
      font-size: 3em;
      max-width: 600px;
      margin: auto auto;
      text-align: center;
      text-shadow: 2px 2px 10px black;
	    text-transform: uppercase;
      font-weight: 700;
      padding-top: 200px;
   }
}




