/*Footer Styles*/

#footer {
	background-color: $dark;
	color: rgba(255, 255, 255, 0.8);
	font-size: 0.6em;
	text-transform: uppercase;
	padding: $base-unit;
	text-align: center;
	display: block;

	p {
		margin: 0;
	}
}



.footer-credits {
	text-align: center;
	font-size: 0.8em;
	// text-transform: uppercase;
	background-color: lighten($concrete, 40%);
	margin: 0;
	padding: 10px;
	p {
		margin: 0;
	}
}