/*/
Theme Name: Perry Rink Constructors
Author: Sasha Rink
Author URI: http://sasharink.com/
Description: A custom made WordPress theme.
Version: 1.0
Tags: b

*/

@import 
	"sass/_mixins",
	"sass/_variables",
	"sass/_nav.scss",
	"sass/_front-page.scss",
	"sass/_footer.scss",
	"sass/_typography.scss",
	"sass/_page.scss",
	"sass/_page-apply.scss",
	"sass/_page-contact.scss",
	"sass/_page-projects.scss"
;

