#page--projects {
   padding: 100px 0;
   @include phone {
      padding: 40px 0;
   }

   p {
      line-height: 1.5em;
   }
}

.project-info {
   background-color: lighten($concrete, 45%);
   padding: $base-unit;
   margin-bottom: $base-unit;
}

.image-projects {
   background: 
		linear-gradient(
	      rgba(46, 49, 49, 0.4), 
	      rgba(108, 122, 137, 0.8)
	    ),
	    url(./img/prc-projects.jpg);
   background-size: cover;
   background-position: center center;
   height: 500px;
   margin-top: -20px;
   
   .heading {
      // letter-spacing: 0.1em;
      color: white;
      font-size: 3em;
      max-width: 600px;
      margin: auto auto;
      text-align: center;
      text-shadow: 2px 2px 10px black;
	    text-transform: uppercase;
      font-weight: 700;
      padding-top: 200px;
   }
}
.single-project-title {
   margin-top: $base-unit;
}

.projects-link {
      text-transform: uppercase;
      color: $blue;
      font-weight: 700;
      font-size: 0.8em;
      transition: all 0.3s;
      padding-bottom: $base-unit;
      &:hover {
         opacity: 0.5;
         text-decoration: none;
      }
   }

.blog-col {
   margin-bottom: $base-unit;
}

.project-content {
   padding: 100px 0;
}

.project-meta {
   opacity: 0.6;
   text-transform: uppercase;
   font-weight: 700;
   font-size: 0.8em;
}

.project-images img {
   width: 100%;
   height: auto;
}
