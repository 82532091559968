.image-apply {
   background: 
		linear-gradient(
	      rgba(46, 49, 49, 0.4), 
	      rgba(108, 122, 137, 0.8)
	    ),
	    url(./img/prc-team3.jpg);
   background-size: cover;
   background-position: bottom center;
   height: 500px;
   margin-top: -20px;
   
   .heading {
      // letter-spacing: 0.1em;
      color: white;
      font-size: 3em;
      max-width: 600px;
      margin: auto auto;
      text-align: center;
      text-shadow: 2px 2px 10px black;
	    text-transform: uppercase;
      font-weight: 700;
      padding-top: 200px;
   }
}

input[type=file] {
  max-width: 100%;
}

input {
  width: 100%;
}

input[type=text],
input[type=email],
input[type=tel],
input[type=number],
textarea {
  background-color: lighten($concrete, 45%);
  border: none;
  padding: $base-unit;
  width: 100%;
}

input[type=submit]{
  margin: auto auto;
  background-color: $concrete;
  color: white;
  border: none;
  padding: 10px;
             
        &:hover {
            color: white;
            background-color: $dark;
          }
}



