#front-page--landing {
	padding: 100px 0;
}

#front-page--about {
	padding: 100px 0;
	@include phone {
		padding: 40px 0;
	}
}

.about-link {
		text-transform: uppercase;

		font-weight: 700;
		font-size: 0.8em;
		transition: all 0.3s;
		&:hover {
			opacity: 0.5;
			text-decoration: none;
			padding-left: $base-unit;
		}
	}



#front-page--team {
	padding: 100px 0;
	margin: 100px auto;
	color: white;
	background: 
		linear-gradient(
	      rgba(46, 49, 49, 0.5), 
	      rgba(108, 122, 137, 0.7)
	    ),
	    url(./img/prc-team2.jpg);
	background-size: cover;
	background-position: bottom center;
	.row {
		margin: 0;
	}
	@include phone {
		padding: 60px 0;
		margin: 40px 0;
	}
}

.apply-btn {
	margin: auto auto;
	background-color: transparent;
    color: white;
    border: 1px solid white;
    padding: 10px;
    font-size: 1.2em;
    border-radius: 0;
             
        &:hover {
            color: $dark;
            border-color: none;
            background-color: white;
          }
}

// Projects Partial

#front-page--projects {
	background-color: lighten($concrete, 45%);
	padding: 100px;
	@include phone {
		padding: 50px 8px;
	}
	@include tablet {
		padding: 50px 8px;
	}

	h2 {
		text-align: center;
		color: $dark;
		text-transform: uppercase;
		

	}
	.front-page-projects-link {
		text-transform: uppercase;
		text-align: center;
		font-weight: 700;
		font-size: 0.8em;
		transition: all 0.3s;
		&:hover {
			opacity: 0.5;
			text-decoration: none;
			padding-left: $base-unit;
		}
	}
}





// Contact Form

